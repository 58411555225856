
@keyframes pulse{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media(prefers-reduced-motion: no-preference) {
  .icon-frigid{
    animation: pulse infinite 1s alternate;
    color:#2c5dbf;
  }
  .icon-temp-down{
    animation: pulse infinite 2s alternate;
    color:#4e8ad9;
  }
  .icon-cool{
    color:#8db9f2;
  }
  .icon-ok{
    color: #00af00;
  }
  .icon-warning{
    color: #dfdf40;
  }
  .icon-caution{
    animation: pulse infinite 2s alternate;
    color: #ffb500;
  }
  .icon-extreme-caution{
    animation: pulse infinite 1.5s alternate;
    color:#ff7c00;
  }
  .icon-danger{
    animation: pulse infinite 1s alternate;
    color: #ff5c00;
  }
  .icon-extreme-danger{
    animation: pulse infinite 0.5s alternate;
    color: #ff1c00;
  }
}