.chipStateDisconnected {
  fill: red;
  animation: blinker 2s linear infinite;
}

.chipStateOk {
  fill: green;
}

.alarm {
  fill: red;
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}